import {
  Typography,
  Chip,
  Divider,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useQueries } from "@tanstack/react-query";
import { getSpeakingQuestionsByTypeNameAndId } from "../../utilities/request";
import RecordingStack from "../RecordingStack";

interface RenderPartThreeProps {
  p3: string[];
  student: string;
}

const RenderPartThree: React.FC<RenderPartThreeProps> = ({ p3, student }) => {
  const partThreeQueries = useQueries({
    queries: p3.map((id: string) => {
      return {
        queryKey: ["PartThree", id],
        queryFn: () => getSpeakingQuestionsByTypeNameAndId("PartThree", id),
        select: (data: any) => data.data.getPartThree,
      };
    }),
  });
  const isSuccess = partThreeQueries.every((q: any) => q.isSuccess);
  const data = partThreeQueries.map((q: any) => q.data);
  return (
    <>
      <Chip color="primary" label="Part Three" sx={{ mt: 2 }} />
      <Divider sx={{ my: 2 }} />
      {isSuccess &&
        data.map((q: any, i: number) => (
          <Accordion key={q.id} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ userSelect: "text" }}>{`${i + 1}. ${
                q.question
              }`}</Typography>
            </AccordionSummary>
            <RecordingStack typeName={q.typeName} id={q.id} student={student} />
          </Accordion>
        ))}
    </>
  );
};

export default RenderPartThree;
