import {
  SwipeableDrawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PeopleIcon from "@mui/icons-material/People";

const menuData = [
  { text: "Home", link: "/", icon: <HomeIcon color="primary" /> },
  {
    text: "Question Bank",
    link: "/questionBank",
    icon: <AccountBalanceIcon color="primary" />,
  },
  {
    text: "Shadowing",
    link: "/shadowing",
    icon: <PeopleIcon color="primary" />,
  },
];

interface MenuDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const MenuDrawer: React.FC<MenuDrawerProps> = ({ open, setOpen }) => {
  return (
    <SwipeableDrawer
      open={open}
      anchor="left"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Box sx={{ width: 250 }} role="presentation">
        <List>
          {menuData.map(({ text, link, icon }) => (
            <NavLink to={link} style={{ textDecoration: "none" }} key={text}>
              {({ isActive }) => (
                <ListItem
                  disablePadding
                  sx={{
                    bgcolor: isActive ? "action.focus" : "default",
                    color: "text.primary",
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              )}
            </NavLink>
          ))}
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default MenuDrawer;
