import { Box, CircularProgress, Typography } from "@mui/material";

interface LoaderProp {
  text?: string;
}

const Loader: React.FC<LoaderProp> = ({ text }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", flexGrow: 1, my: 2 }}>
      <CircularProgress size={20} />
      {text && <Typography sx={{ ml: 2 }}>{text}...</Typography>}
    </Box>
  );
};

export default Loader;
