import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface MultiSelectProps {
  options: string[];
  value: string[];
  setValue: (value: string[]) => void;
  label: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  value,
  setValue,
  label,
}) => {
  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    setValue(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const inputLabelId = `multi-select-outlined-${label}`;

  const selectId = `multi-select-outlined`;

  return (
    <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id={inputLabelId}>{label}</InputLabel>
      <Select
        labelId={inputLabelId}
        id={selectId}
        multiple
        value={value}
        onChange={handleChange}
        label={label}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
