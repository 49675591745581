import { Box } from "@mui/material";

import SamplePlayer from "./SamplePlayer";
import PronunciationAssessment from "./PronunciationAssessment";

const ShadowingComponent: React.FC = () => {
  return (
    <Box>
      <SamplePlayer />
      <PronunciationAssessment />
    </Box>
  );
};

export default ShadowingComponent;
