/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createPartOneTopic = /* GraphQL */ `mutation CreatePartOneTopic(
  $input: CreatePartOneTopicInput!
  $condition: ModelPartOneTopicConditionInput
) {
  createPartOneTopic(input: $input, condition: $condition) {
    id
    topic
    typeName
    season
    questions {
      items {
        id
        typeName
        topicID
        question
        questionType
        season
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePartOneTopicMutationVariables,
  APITypes.CreatePartOneTopicMutation
>;
export const updatePartOneTopic = /* GraphQL */ `mutation UpdatePartOneTopic(
  $input: UpdatePartOneTopicInput!
  $condition: ModelPartOneTopicConditionInput
) {
  updatePartOneTopic(input: $input, condition: $condition) {
    id
    topic
    typeName
    season
    questions {
      items {
        id
        typeName
        topicID
        question
        questionType
        season
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePartOneTopicMutationVariables,
  APITypes.UpdatePartOneTopicMutation
>;
export const deletePartOneTopic = /* GraphQL */ `mutation DeletePartOneTopic(
  $input: DeletePartOneTopicInput!
  $condition: ModelPartOneTopicConditionInput
) {
  deletePartOneTopic(input: $input, condition: $condition) {
    id
    topic
    typeName
    season
    questions {
      items {
        id
        typeName
        topicID
        question
        questionType
        season
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePartOneTopicMutationVariables,
  APITypes.DeletePartOneTopicMutation
>;
export const createPartOne = /* GraphQL */ `mutation CreatePartOne(
  $input: CreatePartOneInput!
  $condition: ModelPartOneConditionInput
) {
  createPartOne(input: $input, condition: $condition) {
    id
    typeName
    topicID
    question
    topic {
      id
      topic
      typeName
      season
      questions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    questionType
    season
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePartOneMutationVariables,
  APITypes.CreatePartOneMutation
>;
export const updatePartOne = /* GraphQL */ `mutation UpdatePartOne(
  $input: UpdatePartOneInput!
  $condition: ModelPartOneConditionInput
) {
  updatePartOne(input: $input, condition: $condition) {
    id
    typeName
    topicID
    question
    topic {
      id
      topic
      typeName
      season
      questions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    questionType
    season
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePartOneMutationVariables,
  APITypes.UpdatePartOneMutation
>;
export const deletePartOne = /* GraphQL */ `mutation DeletePartOne(
  $input: DeletePartOneInput!
  $condition: ModelPartOneConditionInput
) {
  deletePartOne(input: $input, condition: $condition) {
    id
    typeName
    topicID
    question
    topic {
      id
      topic
      typeName
      season
      questions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    questionType
    season
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePartOneMutationVariables,
  APITypes.DeletePartOneMutation
>;
export const createPartTwo = /* GraphQL */ `mutation CreatePartTwo(
  $input: CreatePartTwoInput!
  $condition: ModelPartTwoConditionInput
) {
  createPartTwo(input: $input, condition: $condition) {
    id
    typeName
    prompt
    subQuestions
    season
    type
    partThree {
      items {
        id
        typeName
        question
        partTwoID
        type
        season
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePartTwoMutationVariables,
  APITypes.CreatePartTwoMutation
>;
export const updatePartTwo = /* GraphQL */ `mutation UpdatePartTwo(
  $input: UpdatePartTwoInput!
  $condition: ModelPartTwoConditionInput
) {
  updatePartTwo(input: $input, condition: $condition) {
    id
    typeName
    prompt
    subQuestions
    season
    type
    partThree {
      items {
        id
        typeName
        question
        partTwoID
        type
        season
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePartTwoMutationVariables,
  APITypes.UpdatePartTwoMutation
>;
export const deletePartTwo = /* GraphQL */ `mutation DeletePartTwo(
  $input: DeletePartTwoInput!
  $condition: ModelPartTwoConditionInput
) {
  deletePartTwo(input: $input, condition: $condition) {
    id
    typeName
    prompt
    subQuestions
    season
    type
    partThree {
      items {
        id
        typeName
        question
        partTwoID
        type
        season
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePartTwoMutationVariables,
  APITypes.DeletePartTwoMutation
>;
export const createPartThree = /* GraphQL */ `mutation CreatePartThree(
  $input: CreatePartThreeInput!
  $condition: ModelPartThreeConditionInput
) {
  createPartThree(input: $input, condition: $condition) {
    id
    typeName
    question
    partTwoID
    partTwo {
      id
      typeName
      prompt
      subQuestions
      season
      type
      partThree {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    type
    season
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePartThreeMutationVariables,
  APITypes.CreatePartThreeMutation
>;
export const updatePartThree = /* GraphQL */ `mutation UpdatePartThree(
  $input: UpdatePartThreeInput!
  $condition: ModelPartThreeConditionInput
) {
  updatePartThree(input: $input, condition: $condition) {
    id
    typeName
    question
    partTwoID
    partTwo {
      id
      typeName
      prompt
      subQuestions
      season
      type
      partThree {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    type
    season
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePartThreeMutationVariables,
  APITypes.UpdatePartThreeMutation
>;
export const deletePartThree = /* GraphQL */ `mutation DeletePartThree(
  $input: DeletePartThreeInput!
  $condition: ModelPartThreeConditionInput
) {
  deletePartThree(input: $input, condition: $condition) {
    id
    typeName
    question
    partTwoID
    partTwo {
      id
      typeName
      prompt
      subQuestions
      season
      type
      partThree {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    type
    season
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePartThreeMutationVariables,
  APITypes.DeletePartThreeMutation
>;
export const createSpeakingRecord = /* GraphQL */ `mutation CreateSpeakingRecord(
  $input: CreateSpeakingRecordInput!
  $condition: ModelSpeakingRecordConditionInput
) {
  createSpeakingRecord(input: $input, condition: $condition) {
    id
    title
    student
    type
    typeID
    text
    audio
    final
    note
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSpeakingRecordMutationVariables,
  APITypes.CreateSpeakingRecordMutation
>;
export const updateSpeakingRecord = /* GraphQL */ `mutation UpdateSpeakingRecord(
  $input: UpdateSpeakingRecordInput!
  $condition: ModelSpeakingRecordConditionInput
) {
  updateSpeakingRecord(input: $input, condition: $condition) {
    id
    title
    student
    type
    typeID
    text
    audio
    final
    note
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSpeakingRecordMutationVariables,
  APITypes.UpdateSpeakingRecordMutation
>;
export const deleteSpeakingRecord = /* GraphQL */ `mutation DeleteSpeakingRecord(
  $input: DeleteSpeakingRecordInput!
  $condition: ModelSpeakingRecordConditionInput
) {
  deleteSpeakingRecord(input: $input, condition: $condition) {
    id
    title
    student
    type
    typeID
    text
    audio
    final
    note
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSpeakingRecordMutationVariables,
  APITypes.DeleteSpeakingRecordMutation
>;
export const createSpeakingHomework = /* GraphQL */ `mutation CreateSpeakingHomework(
  $input: CreateSpeakingHomeworkInput!
  $condition: ModelSpeakingHomeworkConditionInput
) {
  createSpeakingHomework(input: $input, condition: $condition) {
    id
    student
    title
    instruction
    dueDate
    p1t
    p1
    p2
    p2n3
    p3
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSpeakingHomeworkMutationVariables,
  APITypes.CreateSpeakingHomeworkMutation
>;
export const updateSpeakingHomework = /* GraphQL */ `mutation UpdateSpeakingHomework(
  $input: UpdateSpeakingHomeworkInput!
  $condition: ModelSpeakingHomeworkConditionInput
) {
  updateSpeakingHomework(input: $input, condition: $condition) {
    id
    student
    title
    instruction
    dueDate
    p1t
    p1
    p2
    p2n3
    p3
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSpeakingHomeworkMutationVariables,
  APITypes.UpdateSpeakingHomeworkMutation
>;
export const deleteSpeakingHomework = /* GraphQL */ `mutation DeleteSpeakingHomework(
  $input: DeleteSpeakingHomeworkInput!
  $condition: ModelSpeakingHomeworkConditionInput
) {
  deleteSpeakingHomework(input: $input, condition: $condition) {
    id
    student
    title
    instruction
    dueDate
    p1t
    p1
    p2
    p2n3
    p3
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSpeakingHomeworkMutationVariables,
  APITypes.DeleteSpeakingHomeworkMutation
>;
