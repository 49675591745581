import {
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useQueries } from "@tanstack/react-query";
import { getSpeakingQuestionsByTypeNameAndId } from "../../utilities/request";
import RecordingStack from "../RecordingStack";

interface RenderPartOneProps {
  p1: string[];
  student: string;
}

const RenderPartOne: React.FC<RenderPartOneProps> = ({ p1, student }) => {
  const partOneQueries = useQueries({
    queries: p1.map((id: string) => {
      return {
        queryKey: ["PartOne", id],
        queryFn: () => getSpeakingQuestionsByTypeNameAndId("PartOne", id),
        select: (data: any) => data.data.getPartOne,
      };
    }),
  });
  const isSuccess = partOneQueries.every((q) => q.isSuccess);
  const data = partOneQueries.map((q) => q.data);
  return (
    <>
      <Chip color="primary" label="Part One" sx={{ mt: 2 }} />
      <Divider sx={{ my: 2 }} />
      {isSuccess &&
        data.map((q: any, i: number) => (
          <Accordion key={q.id} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ userSelect: "text" }}>{`${i + 1}. ${
                q.question
              }`}</Typography>
            </AccordionSummary>
            <RecordingStack typeName={q.typeName} id={q.id} student={student} />
          </Accordion>
        ))}
    </>
  );
};

export default RenderPartOne;
