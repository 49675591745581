/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getPartOneTopic = /* GraphQL */ `query GetPartOneTopic($id: ID!) {
  getPartOneTopic(id: $id) {
    id
    topic
    typeName
    season
    questions {
      items {
        id
        typeName
        topicID
        question
        questionType
        season
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPartOneTopicQueryVariables,
  APITypes.GetPartOneTopicQuery
>;
export const listPartOneTopics = /* GraphQL */ `query ListPartOneTopics(
  $filter: ModelPartOneTopicFilterInput
  $limit: Int
  $nextToken: String
) {
  listPartOneTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      topic
      typeName
      season
      questions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPartOneTopicsQueryVariables,
  APITypes.ListPartOneTopicsQuery
>;
export const getPartOne = /* GraphQL */ `query GetPartOne($id: ID!) {
  getPartOne(id: $id) {
    id
    typeName
    topicID
    question
    topic {
      id
      topic
      typeName
      season
      questions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    questionType
    season
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPartOneQueryVariables,
  APITypes.GetPartOneQuery
>;
export const listPartOnes = /* GraphQL */ `query ListPartOnes(
  $filter: ModelPartOneFilterInput
  $limit: Int
  $nextToken: String
) {
  listPartOnes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      typeName
      topicID
      question
      topic {
        id
        topic
        typeName
        season
        createdAt
        updatedAt
        __typename
      }
      questionType
      season
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPartOnesQueryVariables,
  APITypes.ListPartOnesQuery
>;
export const getPartTwo = /* GraphQL */ `query GetPartTwo($id: ID!) {
  getPartTwo(id: $id) {
    id
    typeName
    prompt
    subQuestions
    season
    type
    partThree {
      items {
        id
        typeName
        question
        partTwoID
        type
        season
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPartTwoQueryVariables,
  APITypes.GetPartTwoQuery
>;
export const listPartTwos = /* GraphQL */ `query ListPartTwos(
  $filter: ModelPartTwoFilterInput
  $limit: Int
  $nextToken: String
) {
  listPartTwos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      typeName
      prompt
      subQuestions
      season
      type
      partThree {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPartTwosQueryVariables,
  APITypes.ListPartTwosQuery
>;
export const getPartThree = /* GraphQL */ `query GetPartThree($id: ID!) {
  getPartThree(id: $id) {
    id
    typeName
    question
    partTwoID
    partTwo {
      id
      typeName
      prompt
      subQuestions
      season
      type
      partThree {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    type
    season
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPartThreeQueryVariables,
  APITypes.GetPartThreeQuery
>;
export const listPartThrees = /* GraphQL */ `query ListPartThrees(
  $filter: ModelPartThreeFilterInput
  $limit: Int
  $nextToken: String
) {
  listPartThrees(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      typeName
      question
      partTwoID
      partTwo {
        id
        typeName
        prompt
        subQuestions
        season
        type
        createdAt
        updatedAt
        __typename
      }
      type
      season
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPartThreesQueryVariables,
  APITypes.ListPartThreesQuery
>;
export const getSpeakingRecord = /* GraphQL */ `query GetSpeakingRecord($id: ID!) {
  getSpeakingRecord(id: $id) {
    id
    title
    student
    type
    typeID
    text
    audio
    final
    note
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSpeakingRecordQueryVariables,
  APITypes.GetSpeakingRecordQuery
>;
export const listSpeakingRecords = /* GraphQL */ `query ListSpeakingRecords(
  $filter: ModelSpeakingRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  listSpeakingRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      student
      type
      typeID
      text
      audio
      final
      note
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSpeakingRecordsQueryVariables,
  APITypes.ListSpeakingRecordsQuery
>;
export const getSpeakingHomework = /* GraphQL */ `query GetSpeakingHomework($id: ID!) {
  getSpeakingHomework(id: $id) {
    id
    student
    title
    instruction
    dueDate
    p1t
    p1
    p2
    p2n3
    p3
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSpeakingHomeworkQueryVariables,
  APITypes.GetSpeakingHomeworkQuery
>;
export const listSpeakingHomeworks = /* GraphQL */ `query ListSpeakingHomeworks(
  $filter: ModelSpeakingHomeworkFilterInput
  $limit: Int
  $nextToken: String
) {
  listSpeakingHomeworks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      student
      title
      instruction
      dueDate
      p1t
      p1
      p2
      p2n3
      p3
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSpeakingHomeworksQueryVariables,
  APITypes.ListSpeakingHomeworksQuery
>;
export const partOnesByTopicID = /* GraphQL */ `query PartOnesByTopicID(
  $topicID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPartOneFilterInput
  $limit: Int
  $nextToken: String
) {
  partOnesByTopicID(
    topicID: $topicID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      typeName
      topicID
      question
      topic {
        id
        topic
        typeName
        season
        createdAt
        updatedAt
        __typename
      }
      questionType
      season
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PartOnesByTopicIDQueryVariables,
  APITypes.PartOnesByTopicIDQuery
>;
export const partThreesByPartTwoID = /* GraphQL */ `query PartThreesByPartTwoID(
  $partTwoID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPartThreeFilterInput
  $limit: Int
  $nextToken: String
) {
  partThreesByPartTwoID(
    partTwoID: $partTwoID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      typeName
      question
      partTwoID
      partTwo {
        id
        typeName
        prompt
        subQuestions
        season
        type
        createdAt
        updatedAt
        __typename
      }
      type
      season
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PartThreesByPartTwoIDQueryVariables,
  APITypes.PartThreesByPartTwoIDQuery
>;
export const recordByStudentTypeTypeID = /* GraphQL */ `query RecordByStudentTypeTypeID(
  $student: String!
  $typeTypeID: ModelSpeakingRecordRecordByStudentTypeTypeIDCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSpeakingRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  recordByStudentTypeTypeID(
    student: $student
    typeTypeID: $typeTypeID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      student
      type
      typeID
      text
      audio
      final
      note
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RecordByStudentTypeTypeIDQueryVariables,
  APITypes.RecordByStudentTypeTypeIDQuery
>;
export const homeworkByStudent = /* GraphQL */ `query HomeworkByStudent(
  $student: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSpeakingHomeworkFilterInput
  $limit: Int
  $nextToken: String
) {
  homeworkByStudent(
    student: $student
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      student
      title
      instruction
      dueDate
      p1t
      p1
      p2
      p2n3
      p3
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HomeworkByStudentQueryVariables,
  APITypes.HomeworkByStudentQuery
>;
