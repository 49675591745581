import { useState, useEffect, useRef } from "react";
import { Box, Grid, Typography } from "@mui/material";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import toWav from "audiobuffer-to-wav";
// import useAzureToken from "../../hooks/azureTokenHook";
import Word from "./Word";
import useStore from "./store";
import Loader from "../Loader";
import PronScore from "./PronScore";
import SimpleAudioPlayer from "../SimpleAudioPlayer";
import InfoBox from "../../layouts/InfoBox";
import { getTokenOrRefresh } from "../../utilities/getToken";

const PronunciationAssessment: React.FC = () => {
  const { text, result, recordUrl } = useStore((state: any) => ({
    text: state.textList[state.activeIndex].text,
    result: state.textList[state.activeIndex].result,
    recordUrl: state.textList[state.activeIndex].recordUrl,
  }));
  const handleActiveTextChange = useStore(
    (state: any) => state.handleActiveTextChange
  );
  const [loading, setLoading] = useState<boolean>(false);
  // const { token, error: tokenError } = useAzureToken();
  const recorderControls = useAudioRecorder();
  const { mediaRecorder } = recorderControls;
  const recordedChunks = useRef<any>([]);

  const startRecognizing = async (file: any) => {
    const token = await getTokenOrRefresh();
    const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(
      token,
      "eastasia"
    );
    speechConfig.speechRecognitionLanguage = "en-US";
    const audioConfig = sdk.AudioConfig.fromWavFileInput(file);
    const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
    let pronunciationAssessmentConfig =
      sdk.PronunciationAssessmentConfig.fromJSON(
        `{"referenceText":${JSON.stringify(
          text
        )},"gradingSystem":"HundredMark","granularity":"Phoneme","EnableMiscue":"true", "phonemeAlphabet":"IPA"}`
      );

    pronunciationAssessmentConfig.applyTo(recognizer);
    recognizer.recognizeOnceAsync(
      (rawResult) => {
        const pronunciation_result =
          sdk.PronunciationAssessmentResult.fromResult(rawResult);
        handleActiveTextChange("result", pronunciation_result.detailResult);
        recognizer.close();
        setLoading(false);
      },
      (error) => {
        console.error("pronunciation error", error);
        setLoading(false);
      }
    );
  };

  const onRecordingComplete = async (blob: any) => {
    setLoading(true);
    const url = URL.createObjectURL(blob);
    handleActiveTextChange("recordUrl", url);
    const wavBlob = await convertToWav();
    const file = new File([wavBlob], "output.wav", { type: "audio/wav" });
    startRecognizing(file);
  };
  const convertToWav = async () => {
    const audioContext = new (window.AudioContext || window.AudioContext)();
    const audioBuffer = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        audioContext.decodeAudioData(reader.result as ArrayBuffer, resolve);
      };
      reader.readAsArrayBuffer(
        new Blob(recordedChunks.current, { type: "audio/webm" })
      );
    });

    const wav = toWav(audioBuffer as AudioBuffer);
    recordedChunks.current = [];
    return new Blob([new DataView(wav)], { type: "audio/wav" });
  };
  useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.onstart = () => {
        handleActiveTextChange("result", null);
        mediaRecorder.ondataavailable = (event: any) => {
          if (event.data.size > 0) {
            recordedChunks.current.push(event.data);
          }
        };
      };
    }
  }, [mediaRecorder]);
  return (
    <Box>
      <Box
        sx={{
          display: loading ? "none" : "flex",
          justifyContent: "center",
          marginBottom: 2,
        }}
      >
        <AudioRecorder
          onRecordingComplete={onRecordingComplete}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          downloadOnSavePress={false}
          downloadFileExtension="wav"
          showVisualizer={true}
          recorderControls={recorderControls}
          classes={{ AudioRecorderClass: "audioRecording" }}
        />
      </Box>

      {loading && <Loader text="Accessing" />}

      {result && (
        <InfoBox>
          <Grid
            container
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SimpleAudioPlayer audioURL={recordUrl} />
            </Grid>
            <Grid
              item
              xs={11}
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Typography>
                {result.Words.map((word: any, index: number) => (
                  <Word word={word} key={index} />
                ))}
              </Typography>
            </Grid>
          </Grid>
        </InfoBox>
      )}
      {result && <PronScore result={result} />}
    </Box>
  );
};

export default PronunciationAssessment;
