import {
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useQueries } from "@tanstack/react-query";
import { getSpeakingQuestionsByTypeNameAndId } from "../../utilities/request";
import RecordingStack from "../RecordingStack";

interface RenderPartTwoListProps {
  p2: string[];
  student: string;
}

const RenderPartTwoList: React.FC<RenderPartTwoListProps> = ({
  p2,
  student,
}) => {
  const partTwoQueries = useQueries({
    queries: p2.map((id: string) => {
      return {
        queryKey: ["PartTwo", id],
        queryFn: () => getSpeakingQuestionsByTypeNameAndId("PartTwo", id),
        select: (data: any) => data.data.getPartTwo,
      };
    }),
  });
  const isSuccess = partTwoQueries.every((q) => q.isSuccess);
  const data = partTwoQueries.map((q) => q.data);
  return (
    <>
      <Chip color="primary" label="Part Two" sx={{ my: 2 }} />
      <Divider sx={{ my: 2 }} />
      {isSuccess &&
        data.map((q: any, i: number) => (
          <Accordion key={q.id} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ userSelect: "text" }}>{`${i + 1}. Describe ${
                q.prompt
              }`}</Typography>
            </AccordionSummary>
            <RecordingStack
              typeName={q.typeName}
              id={q.id}
              student={student}
              subQuestions={q.subQuestions}
            />
          </Accordion>
        ))}
    </>
  );
};

export default RenderPartTwoList;
