import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Phonemes from "./Phonmes";

interface WordProps {
  word: any;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const Word: React.FC<WordProps> = ({ word }) => {
  const text = word.Word;
  const errorType = word.PronunciationAssessment.ErrorType;
  const bgColor =
    errorType === "Omission"
      ? "primary.main"
      : errorType === "Mispronunciation"
      ? "warning.main"
      : "none";
  const isOmission = errorType === "Omission";
  const isMispronunciation = errorType === "Mispronunciation";
  const isInsertion = errorType === "Insertion";
  const isError = isOmission || isMispronunciation || isInsertion;

  return (
    <>
      <HtmlTooltip
        title={isOmission ? null : <Phonemes word={word} />}
        arrow
        placement="top"
      >
        <Box
          component="span"
          sx={{
            border: isError ? "1px solid black" : null,
            borderRadius: "4px",
            bgcolor: bgColor,
            color: isOmission ? "white" : "defalut",
            textDecoration: errorType === "Insertion" ? "line-through" : "none",
          }}
        >
          {isOmission && "["}
          {text}
          {isOmission && "]"}
        </Box>
      </HtmlTooltip>{" "}
    </>
  );
};

export default Word;
