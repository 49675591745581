import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import BasicTable from "./BasicTable";
import { speakingTypeNames, speakingPromptTypes } from "../customConfig";
import { useQueries } from "@tanstack/react-query";
import { getSpeakingQuestionsByTypeNameAndId } from "../utilities/request";
import { capitalize } from "../utilities";
import Loader from "./Loader";

interface HomeworkStackProps {
  type: "p1" | "p2" | "p3" | "p1t" | "p2n3";
  hw: any;
  setHw: (hw: any) => void;
}

const HomeworkStack: React.FC<HomeworkStackProps> = ({ type, hw, setHw }) => {
  const [open, setOpen] = useState(false);
  const typeName = speakingTypeNames[type];
  const idArray = hw[type] || [];

  const hwQuestionQueries = useQueries({
    queries: idArray.map((id: string) => {
      return {
        queryKey: [typeName, id],
        queryFn: () => getSpeakingQuestionsByTypeNameAndId(typeName, id),
        enabled: !!id,
        select: (data: any) => {
          const capitalizedTypeName = capitalize(typeName);
          const prompt = speakingPromptTypes[type];
          const question = data.data[`get${capitalizedTypeName}`][prompt];
          const id = data.data[`get${capitalizedTypeName}`].id;
          return { id, question };
        },
      };
    }),
  });

  const handleDelete = (id: string) => {
    setHw((prevHw: any) => {
      const newArray = prevHw[type].filter(
        (questionId: string) => questionId !== id
      );
      return { ...prevHw, [type]: newArray };
    });
  };
  const handleSelect = (selectedQuestionId: any[]) => {
    setHw((prevHw: any) => {
      let newArray: string[];
      if (prevHw[type]) {
        newArray = [...prevHw[type], ...selectedQuestionId];
      } else {
        newArray = selectedQuestionId;
      }
      return { ...prevHw, [type]: newArray };
    });
    setOpen(false);
  };
  const allSuccessful = hwQuestionQueries.every((result) => result.isSuccess);
  if (!allSuccessful) {
    return <Loader />;
  }
  const questions = hwQuestionQueries.map((query) => query?.data);
  return (
    <Box>
      <Typography variant="h6">{type}:</Typography>
      <List>
        {questions &&
          questions.length > 0 &&
          questions.map((question: any) => (
            <ListItem
              key={question.id}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete(question.id)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={question.question} />
            </ListItem>
          ))}
      </List>
      <IconButton
        color="primary"
        aria-label="add"
        sx={{ display: "block" }}
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>{"add speaking questions"}</DialogTitle>
        <DialogContent>
          <BasicTable
            selectedUser={hw.student}
            handleSelect={handleSelect}
            type={typeName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Divider />
    </Box>
  );
};

export default HomeworkStack;
