import { useAuthenticator } from "@aws-amplify/ui-react";
import { useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { useQuery } from "@tanstack/react-query";
import { listHomeworkByStudent } from "../utilities/request";
import { IconButton, Tooltip } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditIcon from "@mui/icons-material/Edit";
import { isAdmin } from "../utilities";
import UserDropdown from "./UserDropdown";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import useLocalStorage from "../hooks/useLocalStorage";

const HwTable: React.FC = () => {
  const navigate = useNavigate();

  const { user } = useAuthenticator((context) => [context.user]);
  const [selectedUser, setSelectedUser] = useLocalStorage(
    "student",
    user.username
  );

  const { data, isError, isFetching, isLoading } = useQuery({
    queryKey: ["homeworkbyStudent", selectedUser],
    queryFn: () => listHomeworkByStudent(selectedUser || ""),
    select: (data: any) => {
      return data.data.homeworkByStudent.items;
    },
    enabled: !!selectedUser,
  });
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      { accessorKey: "title", header: "Title" },
      {
        accessorFn: (row: any) => dayjs(row.dueDate).format("YYYY-MM-DD HH:mm"),
        header: "Due Time",
      },
    ],
    []
  );

  return (
    <MaterialReactTable
      muiTableContainerProps={{ sx: { minHeight: "400px" } }}
      columns={columns}
      data={data ?? []}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Error loading data",
            }
          : undefined
      }
      renderTopToolbarCustomActions={() => {
        return (
          <>
            {isAdmin(user) && (
              <UserDropdown
                user={selectedUser || ""}
                setUser={setSelectedUser}
                label="student"
              />
            )}
          </>
        );
      }}
      enableRowActions
      renderRowActions={({ row }) => {
        const handleClick = () => {
          navigate("/homework", {
            state: { homework: row.original },
          });
        };
        const handleEdit = () => {
          navigate("/create", {
            state: { homework: row.original },
          });
        };
        return (
          <>
            <Tooltip title="Go to Homework">
              <IconButton color="primary" onClick={handleClick}>
                <ArrowForwardIcon />
              </IconButton>
            </Tooltip>

            {isAdmin(user) && (
              <Tooltip title="Edit">
                <IconButton
                  color="primary"
                  aria-label="edit Homework"
                  onClick={handleEdit}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      }}
      state={{
        isLoading,
        showAlertBanner: isError,
        showProgressBars: isFetching,
      }}
    />
  );
};

export default HwTable;
