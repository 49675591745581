import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import RootLayout from "./layouts/RootLayout";
import HomeworkPage from "./pages/Homework";
import HomeworkDetailPage from "./pages/HomeworkDetail";
import QuestionBankPage from "./pages/QuestionBank";
import QuestionEditionPage from "./pages/QuestionEdition";
import QuestionCreationPage from "./pages/QuestionCreation";
import HomeworkCreationPage from "./pages/HomeworkCreation";
import ShadowingPage from "./pages/ShadowingPage";
import NotFound from "./pages/NotFound";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider, closeSnackbar } from "notistack";
import { Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import theme from "./theme";
import ProtectedRoute from "./components/ProtectedRoute";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 60 * 1000, // 30 minutes in milliseconds
      refetchOnWindowFocus: false,
    },
  },
});

Amplify.configure(awsExports);

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          maxSnack={3}
          action={(snackbarId) => (
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(snackbarId)}
            >
              Dismiss
            </Button>
          )}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Router>
              <RootLayout>
                <Routes>
                  <Route path="/" element={<HomeworkPage />} />
                  <Route path="/homework" element={<HomeworkDetailPage />} />
                  <Route
                    path="/create"
                    element={
                      <ProtectedRoute>
                        <HomeworkCreationPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/edit"
                    element={
                      <ProtectedRoute>
                        <HomeworkCreationPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/questionBank" element={<QuestionBankPage />} />
                  <Route
                    path="/questionBank/create"
                    element={
                      <ProtectedRoute>
                        <QuestionCreationPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/questionBank/edit"
                    element={
                      <ProtectedRoute>
                        <QuestionEditionPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/shadowing" element={<ShadowingPage />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </RootLayout>
            </Router>
          </LocalizationProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default withAuthenticator(App, { hideSignUp: true });
