import { useState, FC, useEffect } from "react";
import { Typography, Box, CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import PartTwoAutoComplete from "./PartTwoAutoComplete";
import { listPartTwos } from "../utilities/request";
import QuestionStack from "../components/QuestionStack";
import PartThreeModal from "../components/PartThreeModal";
import { PartTwo } from "../API";

const PartTwoForm: FC = () => {
  const [partTwo, setPartTwo] = useState<PartTwo | null>(null);
  const { data, isLoading, isFetching, isSuccess, isError, error } = useQuery({
    queryKey: ["partTwos"],
    queryFn: listPartTwos,
    select: (data: any) => data.data.listPartTwos.items,
  });
  useEffect(() => {
    if (isSuccess) {
      if (partTwo) {
        const newPartTwo = data.find((p2: any) => p2.id === partTwo.id);
        setPartTwo(newPartTwo);
      }
    } else if (isError) {
      console.error("Query failed", error);
    }
    // Rerun this effect when the query's data or error changes
  }, [data, error, isSuccess, isError]);
  return (
    <Box>
      <Typography>Part Two form</Typography>

      {isFetching && <CircularProgress color="inherit" size={20} />}
      {isSuccess && (
        <PartTwoAutoComplete
          setPartTwo={setPartTwo}
          partTwo={partTwo}
          partTwos={data}
          isLoading={isLoading}
        />
      )}
      {/* change Question Stack */}
      {partTwo && (
        <QuestionStack topic={partTwo} questionModal={PartThreeModal} />
      )}
    </Box>
  );
};

export default PartTwoForm;
