import { ResultReason } from "microsoft-cognitiveservices-speech-sdk";
import * as speechsdk from "microsoft-cognitiveservices-speech-sdk";
import { getTokenOrRefresh } from "./getToken";

const file2audio = async (audioFile: File): Promise<string> => {
  const token = await getTokenOrRefresh();
  const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
    token,
    "eastasia"
  );
  speechConfig.speechRecognitionLanguage = "en-US";

  const audioConfig = speechsdk.AudioConfig.fromWavFileInput(audioFile);
  const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);

  let transcription = "";

  return new Promise<string>((resolve, reject) => {
    recognizer.recognized = (s, e) => {
      if (e.result.reason === ResultReason.RecognizedSpeech) {
        console.log(`RECOGNIZED: Text=${e.result.text}`);
        transcription += e.result.text + " ";
      } else if (e.result.reason === ResultReason.NoMatch) {
        console.log("NOMATCH: Speech could not be recognized.");
      }
    };

    recognizer.canceled = (s, e) => {
      console.log(`CANCELED: Reason=${e.reason}`);
      if (e.reason === speechsdk.CancellationReason.Error) {
        console.log(`CANCELED: ErrorCode=${e.errorCode}`);
        console.log(`CANCELED: ErrorDetails=${e.errorDetails}`);
        reject(new Error(e.errorDetails));
      }
      recognizer.stopContinuousRecognitionAsync();
    };

    recognizer.sessionStopped = () => {
      console.log("\nSession stopped event.");
      recognizer.stopContinuousRecognitionAsync();
      resolve(transcription.trim());
    };

    recognizer.startContinuousRecognitionAsync(
      () => {
        console.log("Recognition started");
      },
      (err) => {
        console.log("Error starting recognition: " + err);
        reject(err);
      }
    );
  });
};

export default file2audio;
