import {
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from "@mui/material";
import { useState, ChangeEvent } from "react";
import PartOneForm from "../form/PartOneForm";
import PartTwoForm from "../form/PartTwoForm";

const QuestionCreationPage: React.FC = () => {
  const [part, setPart] = useState<"part1" | "part2">("part1");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPart(event.target.value as "part1" | "part2");
  };

  return (
    <Stack sx={{ p: 2 }}>
      <Box>
        <FormControl component="fieldset">
          <FormLabel component="legend">Part</FormLabel>
          <RadioGroup
            row
            aria-label="part"
            name="part1"
            value={part}
            onChange={handleChange}
          >
            <FormControlLabel value="part1" control={<Radio />} label="Part1" />
            <FormControlLabel value="part2" control={<Radio />} label="Part2" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>{part === "part1" ? <PartOneForm /> : <PartTwoForm />}</Box>
    </Stack>
  );
};

export default QuestionCreationPage;
