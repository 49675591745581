import { useState, SyntheticEvent } from "react";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RecordingStack from "../RecordingStack";

interface RenderPartTwoAndThreeProps {
  p2: any;
  student: string;
  index?: number;
}

const RenderPartTwoAndThree: React.FC<RenderPartTwoAndThreeProps> = ({
  p2,
  student,
}) => {
  const [value, setValue] = useState("p2");
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            <Tab label="Part Two" value="p2" />
            <Tab label="Part Three" value="p3" />
          </TabList>
        </Box>
        <TabPanel value="p2" sx={{ p: { xs: 0.5, sm: 1, md: 2, lg: 3 } }}>
          <Typography variant="h6"> Describe {p2.prompt}</Typography>
          <Box
            sx={{
              border: "1px solid black",
              padding: { xs: 0, sm: 1, md: 2 },
              margin: { xs: 0, sm: 1, md: 2 },
            }}
          >
            <ul>
              {p2.subQuestions.map((subQ: string) => (
                <li key={subQ}>{subQ}</li>
              ))}
            </ul>
          </Box>
          <RecordingStack typeName={p2.typeName} id={p2.id} student={student} />
        </TabPanel>
        <TabPanel value="p3" sx={{ p: { xs: 0.5, sm: 1, md: 2, lg: 3 } }}>
          {p2.partThree.items.map((q: any, i: number) => (
            <Accordion key={q.id} TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ userSelect: "text" }}>{`${i + 1}. ${
                  q.question
                }`}</Typography>
              </AccordionSummary>
              <RecordingStack
                typeName={q.typeName}
                id={q.id}
                student={student}
              />
            </Accordion>
          ))}
        </TabPanel>
      </TabContext>
    </>
  );
};

export default RenderPartTwoAndThree;
