import { useState, useEffect, useRef, ChangeEvent } from "react";
import { Storage } from "aws-amplify";
import { Typography, Button, Box } from "@mui/material";
import ReactAudioPlayer from "react-h5-audio-player";
import dayjs from "dayjs";
import "react-h5-audio-player/lib/styles.css";
import Loader from "./Loader";
import { isDesktop } from "react-device-detect";

interface AudioPlayerProps {
  src?: string;
  title?: string;
  text?: string;
  setOpenText?: (open: boolean) => void;
  openText?: boolean;
  s3Key?: string;
  createdAt?: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  src,
  title,
  text,
  setOpenText,
  openText,
  s3Key,
  createdAt,
}) => {
  const [audioUrl, setAudioUrl] = useState<string>("");
  const playerRef = useRef<ReactAudioPlayer | null>(null);

  const handleChangeSpeed = (event: ChangeEvent<HTMLSelectElement>) => {
    const speed = parseFloat(event.target.value);
    if (playerRef.current && playerRef.current.audio.current) {
      playerRef.current.audio.current.playbackRate = speed;
    }
  };
  useEffect(() => {
    if (src) {
      setAudioUrl(src);
    }
    if (s3Key) {
      const fetchAudioUrl = async () => {
        try {
          const signedURL = await Storage.get(s3Key);
          setAudioUrl(signedURL);
        } catch (error) {
          console.log("error", error);
        }
      };
      fetchAudioUrl();
    }
  }, [s3Key, src]);
  if (!audioUrl) return <Loader />;
  return (
    <ReactAudioPlayer
      src={audioUrl}
      preload={isDesktop ? "auto" : "none"}
      // style={{ minWidth: "300px" }}
      autoPlayAfterSrcChange={false}
      showJumpControls={false}
      ref={playerRef}
      header={
        title && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>{title}</Typography>
            <Typography>{dayjs(createdAt).format("YY-MM-DD-HH:mm")}</Typography>
          </Box>
        )
      }
      customAdditionalControls={[
        <select
          id="playbackRate"
          key="playbackRate"
          onChange={handleChangeSpeed}
          defaultValue="1"
        >
          <option value="0.5">0.5x</option>
          <option value="0.75">0.75x</option>
          <option value="1">1x</option>
          <option value="1.5">1.5x</option>
          <option value="2">2x</option>
        </select>,
        <Button
          onClick={() => {
            if (setOpenText) {
              setOpenText(!openText);
            }
          }}
          key="text"
          sx={{ display: text ? "block" : "none" }}
        >
          text
        </Button>,
      ]}
    />
  );
};

export default AudioPlayer;
