/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_appsync_graphqlEndpoint": "https://ka5gqidjbre5pdh2szsgtqsvda.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "aws",
            "endpoint": "https://b9dw6cx05i.execute-api.ap-northeast-2.amazonaws.com/dev",
            "region": "ap-northeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-2:b95140fd-f735-4d2b-b27c-e93651ac8326",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_sQpTjSCEC",
    "aws_user_pools_web_client_id": "7hl5lur0smt480bpna29j3pfrm",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "boxwhy-speaking-amplify90747-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-2",
    "aws_content_delivery_bucket": "boxwhyspeaking-20230903085611-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ap-northeast-2",
    "aws_content_delivery_url": "https://dbum096xx90k2.cloudfront.net"
};


export default awsmobile;
