import useStore from "../components/shadowing/store";
import ShadowingCreation from "../components/shadowing/ShadowingCreation";
import ShadowingDetail from "../components/shadowing/ShadowingDetail";

const ShadowingPage: React.FC = () => {
  const showDetail = useStore((state) => state.showDetail);

  if (showDetail) return <ShadowingDetail />;
  return <ShadowingCreation />;
};

export default ShadowingPage;
