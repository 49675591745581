import { Box, Typography, TextField, Button } from "@mui/material";
import { useState, ChangeEvent } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import UserDropdown from "../components/UserDropdown";
import HomeworkStack from "../components/HomeworkStack";
import { validateHomework } from "../utilities/validation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createSpeakingHomework,
  deleteSpeakingHomework,
  updateSpeakingHomework,
} from "../utilities/request";
import { useNavigate, useLocation } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";

interface hwDataType {
  id?: string;
  student: string;
  title: string;
  instruction: string;
  dueDate: string;
  p1t: string[] | null;
  p1: string[] | null;
  p2: string[] | null;
  p2n3: string[] | null;
  p3: string[] | null;
}

const initialHwData: hwDataType = {
  student: "",
  title: "",
  instruction: "",
  dueDate: dayjs().toString(),
  p1t: [],
  p1: [],
  p2: [],
  p3: [],
  p2n3: [],
};

const HomeworkCreationPage: React.FC = () => {
  const location = useLocation();
  const hwData = location.state?.homework;
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const createHwMutation = useMutation({
    mutationFn: createSpeakingHomework,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["homeworkbyStudent", hw.student],
      });
      enqueueSnackbar("Homework was created successfully", {
        variant: "success",
      });
      setHw(initialHwData);
      navigate("/");
    },
    onError: (error: any) => {
      console.log("Homework creation error", error);
      enqueueSnackbar("Error while creating homework, please try again later", {
        variant: "error",
      });
    },
  });

  const updateHwMutation = useMutation({
    mutationFn: updateSpeakingHomework,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["homeworkbyStudent", hw.student],
      });
      enqueueSnackbar("Homework was updated successfully", {
        variant: "success",
      });
      setHw(initialHwData);
      navigate("/");
    },
    onError: (error: any) => {
      console.log("Homework update error", error);
      enqueueSnackbar("Error while updating homework, please try again later", {
        variant: "error",
      });
    },
  });

  const deleteHwMutation = useMutation({
    mutationFn: deleteSpeakingHomework,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["homeworkbyStudent", hw.student],
      });
      enqueueSnackbar("Homework was deleted successfully", {
        variant: "success",
      });
      setHw(initialHwData);
      navigate("/");
    },
    onError: (error: any) => {
      console.log("Homework delete error", error);
      enqueueSnackbar("Error while deleting homework, please try again later", {
        variant: "error",
      });
    },
  });
  const [hw, setHw] = useState<hwDataType>(hwData || initialHwData);
  const isEdit = !!hw.id;
  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHw({ ...hw, title: event.target.value });
  };
  const handleStudentChange = (user: string) => {
    setHw({ ...hw, student: user });
  };
  const handleSubmit = () => {
    if (validateHomework(hw)) {
      if (isEdit) {
        updateHwMutation.mutate(hw);
      } else {
        createHwMutation.mutate(hw);
      }
    } else {
      alert("Please fill all the fields");
    }
  };
  const handleDelete = () => {
    if (hw.id) {
      deleteHwMutation.mutate(hw.id);
    }
  };
  const handleCopy = () => {
    if (hw.id) {
      setHw({
        student: hw.student,
        title: `Copy of ${hw.title}`,
        instruction: hw.instruction,
        dueDate: hw.dueDate,
        p1: hw.p1 ? [...hw.p1] : hw.p1,
        p2: hw.p2 ? [...hw.p2] : hw.p2,
        p3: hw.p3 ? [...hw.p3] : hw.p3,
        p1t: hw.p1t ? [...hw.p1t] : hw.p1t,
        p2n3: hw.p2n3 ? [...hw.p2n3] : hw.p2n3,
      });
    }
  };
  const handleDayTimeChange = (value: any) => {
    setHw({ ...hw, dueDate: value.toString() });
  };
  return (
    <Box sx={{ m: 3 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {isEdit ? "Edit" : "Create"} Homework
      </Typography>
      <UserDropdown
        user={hw.student}
        setUser={handleStudentChange}
        label="Student"
      />
      <TextField
        label="Title"
        variant="outlined"
        value={hw.title}
        sx={{ display: "block", my: 2 }}
        onChange={handleTitleChange}
      />
      <Typography>Instruction:</Typography>
      <CKEditor
        editor={Editor}
        data={
          hw.instruction || "<p>Write your homework instruction here ...</p>"
        }
        onChange={(_event, editor) => {
          const data = editor.getData();
          setHw({ ...hw, instruction: data });
        }}
      />
      <DateTimePicker
        label="Due Date"
        value={dayjs(hw.dueDate)}
        views={["year", "month", "day", "hours", "minutes"]}
        disablePast
        onChange={handleDayTimeChange}
      />
      <HomeworkStack type={"p1"} hw={hw} setHw={setHw} />
      <HomeworkStack type={"p2"} hw={hw} setHw={setHw} />
      <HomeworkStack type={"p3"} hw={hw} setHw={setHw} />
      <HomeworkStack type={"p1t"} hw={hw} setHw={setHw} />
      <HomeworkStack type={"p2n3"} hw={hw} setHw={setHw} />
      <Button
        variant="contained"
        sx={{ display: "block", mt: 5 }}
        onClick={handleSubmit}
      >
        {isEdit ? "Update" : "Create"}
      </Button>
      {isEdit && (
        <>
          <Button onClick={handleDelete}>delete</Button>
          <Button onClick={handleCopy}>copy</Button>
        </>
      )}
      <Button onClick={() => navigate("/")}>Back</Button>
    </Box>
  );
};

export default HomeworkCreationPage;
