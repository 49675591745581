import { Box, Chip } from "@mui/material";

interface PronScoreProps {
  result: any;
}

const PronScore: React.FC<PronScoreProps> = ({ result }) => {
  const words = result.Words;
  const mispronunciationCount = words.reduce((acc: number, word: any) => {
    if (word.PronunciationAssessment.ErrorType === "Mispronunciation")
      return acc + 1;
    return acc;
  }, 0);
  const insertionCount = words.reduce((acc: number, word: any) => {
    if (word.PronunciationAssessment.ErrorType === "Insertion") return acc + 1;
    return acc;
  }, 0);
  const omissionCount = words.reduce((acc: number, word: any) => {
    if (word.PronunciationAssessment.ErrorType === "Omission") return acc + 1;
    return acc;
  }, 0);

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Chip
        label={`Mispronunciations: ${mispronunciationCount}`}
        color="warning"
      />
      <Chip label={`Omissions: ${omissionCount}`} color="primary" />
      <Chip label={`Insertions: ${insertionCount}`} color="success" />
    </Box>
  );
};

export default PronScore;
