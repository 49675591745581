import { Button, IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuthenticator } from "@aws-amplify/ui-react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material";

const LogoutButton: React.FC = () => {
  const xsUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const { signOut } = useAuthenticator((context) => [context.user]);
  if (xsUp) {
    return (
      <Button
        startIcon={<LogoutIcon />}
        onClick={signOut}
        sx={{ ml: "auto", color: "inherit" }}
      >
        Logout
      </Button>
    );
  }
  return (
    <IconButton
      aria-label="logout"
      onClick={signOut}
      sx={{ ml: "auto", color: "inherit" }}
    >
      <LogoutIcon />
    </IconButton>
  );
};

export default LogoutButton;
