import { Chip, Divider } from "@mui/material";
import { useQueries } from "@tanstack/react-query";
import { getSpeakingQuestionsByTypeNameAndId } from "../../utilities/request";
import RenderPartOneTopic from "./RenderPartOneTopic";

interface RenderPartOneTopicListProps {
  p1t: string[];
  student: string;
}

const RenderPartOneTopicList: React.FC<RenderPartOneTopicListProps> = ({
  p1t,
  student,
}) => {
  const PartOneTopicQueries = useQueries({
    queries: p1t.map((id: string) => {
      return {
        queryKey: ["PartOneTopic", id],
        queryFn: () => getSpeakingQuestionsByTypeNameAndId("PartOneTopic", id),
        select: (data: any) => data.data.getPartOneTopic,
      };
    }),
  });
  const isSuccess = PartOneTopicQueries.every((q) => q.isSuccess);
  const topics = PartOneTopicQueries.map((q) => q.data);
  return (
    <>
      <Chip color="primary" label="Part One Topic" />
      <Divider sx={{ my: 2 }} />
      {isSuccess &&
        topics.map((topic: any, index: number) => (
          <RenderPartOneTopic
            topic={topic}
            index={index}
            student={student}
            key={topic.id}
          />
        ))}
    </>
  );
};

export default RenderPartOneTopicList;
