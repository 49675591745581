import { Box } from "@mui/material";

interface PhonemesProps {
  word: any;
}

const Phonemes: React.FC<PhonemesProps> = ({ word }) => {
  const text = word.Word;
  const accuracyScore = word.PronunciationAssessment.AccuracyScore;
  const phonemes = word.Phonemes;
  const phonemeList = phonemes.map((phoneme: any) => phoneme.Phoneme);
  const scoreList = phonemes.map(
    (phoneme: any) => phoneme.PronunciationAssessment.AccuracyScore
  );
  return (
    <Box>
      <Box>
        {text}: {accuracyScore}
      </Box>
      <table>
        <thead>
          <tr>
            {phonemeList.map((phoneme: any, index: number) => (
              <td key={index}>{phoneme}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {scoreList.map((score: any, index: number) => (
              <td key={index}>{score}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

export default Phonemes;
