import { useAuthenticator } from "@aws-amplify/ui-react";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import { isAdmin } from "../utilities";
import HwTable from "../components/HwTable";

const HomeworkPage: React.FC = () => {
  const { user } = useAuthenticator();
  return (
    <>
      {isAdmin(user) && (
        <>
          <Button component={NavLink} to="/create" variant="contained">
            Add Homework
          </Button>
        </>
      )}
      <HwTable />
    </>
  );
};

export default HomeworkPage;
