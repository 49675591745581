import { Toolbar, Avatar, Typography, AppBar, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import logo from "../../assets/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import MenuDrawer from "./MenuDrawer";
import LogoutButton from "./LogoutButton";

const NavBar: React.FC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <AppBar position="static">
      <MenuDrawer open={openMenu} setOpen={setOpenMenu} />
      <Toolbar>
        <IconButton
          aria-label="menu"
          onClick={() => setOpenMenu(true)}
          sx={{ mr: 2 }}
        >
          <MenuIcon htmlColor="white" />
        </IconButton>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Avatar alt="boxwhy's logo" src={logo} />
        </Link>
        <Typography
          fontWeight="bold"
          sx={{
            flexGrow: 1,
            ml: 2,
            fontSize: { xs: "0.8rem", sm: "1rem" },
          }}
        >
          Boxwhy IELTS Speaking App
        </Typography>
        <LogoutButton />
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
