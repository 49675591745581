import { useAuthenticator } from "@aws-amplify/ui-react";
import { useState, useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { useQuery } from "@tanstack/react-query";
import SingleSelect from "./SingleSelect";
import { listTableData } from "../utilities/request";
import {
  columnOrder,
  selectColumnOrder,
  speakingTableColumns,
} from "../customConfig";
import { Button } from "@mui/material";
import RecordingStack from "./RecordingStack";
import RenderPartOneTopic from "./Render/RenderPartOneTopic";
import RenderPartTwoAndThree from "./Render/RenderPartTwoAndThree";

interface BasicTableProps {
  selectedUser: string | null;
  handleSelect?: (questions: any[]) => void;
  type?: string;
}

const BasicTable: React.FC<BasicTableProps> = ({
  selectedUser,
  handleSelect,
  type,
}) => {
  const { user } = useAuthenticator();
  const [typeName, setTypeName] = useState<any>(type || "PartOneTopic");
  const { data, isError, isFetching, isLoading } = useQuery({
    queryKey: [typeName],
    queryFn: () => listTableData(typeName),
    select: (data) => {
      switch (typeName) {
        case "PartOneTopic":
          return data.data.listPartOneTopics.items;
        case "PartOne":
          return data.data.listPartOnes.items;
        case "PartTwo":
          return data.data.listPartTwos.items;
        case "PartThree":
          return data.data.listPartThrees.items;
        default:
          return [];
      }
    },
  });
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => speakingTableColumns[typeName as keyof typeof speakingTableColumns],
    [typeName]
  );

  const isSelect = !!handleSelect;
  return (
    <MaterialReactTable
      columns={columns}
      data={data ?? []}
      enableRowSelection={!!handleSelect}
      enableExpandAll={false}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Error loading data",
            }
          : undefined
      }
      renderTopToolbarCustomActions={({ table }) => {
        const handleSumbit = () => {
          const selectedQuestionId = table
            .getSelectedRowModel()
            .flatRows.map((row) => {
              return row.original.id;
            });
          if (isSelect) {
            handleSelect?.(selectedQuestionId);
          }
        };
        return (
          <>
            <SingleSelect
              options={["PartOneTopic", "PartOne", "PartTwo", "PartThree"]}
              label="Type"
              value={typeName}
              setValue={setTypeName}
              disabled={!!type}
            />
            {isSelect && (
              <Button
                color="primary"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleSumbit}
                variant="contained"
              >
                submit
              </Button>
            )}
          </>
        );
      }}
      renderDetailPanel={({ row }) => {
        const student = selectedUser || user.username;
        if (row.getIsExpanded() && student) {
          const { typeName, id } = row.original;
          if (["PartOne", "PartTwo", "PartThree"].includes(typeName)) {
            return (
              <RecordingStack
                typeName={typeName}
                id={id}
                student={student}
                subQuestions={row.original.subQuestions}
              />
            );
          } else if (typeName === "PartOneTopic") {
            return (
              <RenderPartOneTopic topic={row.original} student={student} />
            );
          } else {
            return (
              <RenderPartTwoAndThree student={student} p2={row.original} />
            );
          }
        }
      }}
      positionExpandColumn="last"
      state={{
        isLoading,
        showAlertBanner: isError,
        showProgressBars: isFetching,
        columnOrder: isSelect
          ? selectColumnOrder[typeName as keyof typeof columnOrder]
          : columnOrder[typeName as keyof typeof columnOrder],
      }}
    />
  );
};

export default BasicTable;
