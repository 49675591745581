export const seasons = [
  "2023-2",
  "2023-3",
  "2024-1",
  "2024-2",
  "2024-3",
  "2025-1",
  "2025-2",
  "2025-3",
];

export const partOneType = [
  "present",
  "past",
  "preference",
  "how long",
  "future",
  "hypothetical",
  "comparison",
  "past event",
  "listing",
  "people's behavior",
  "others",
  "opinion",
];

export const partTwoType = ["people", "event", "object", "place", "abstract"];

export const partThreeType = [
  "opinion",
  "reason",
  "comparison",
  "listing",
  "solution",
  "advantage&disadvantage",
];

export const speakingTableColumns = {
  PartOneTopic: [
    {
      accessorKey: "topic",
      header: "Topic",
    },
    {
      accessorFn: (row) => row.season.join(", "),
      header: "Season",
      id: "season",
    },
  ],
  PartOne: [
    {
      accessorKey: "question",
      header: "Prompt",
      size: 180,
    },
    {
      accessorFn: (row) => row.topic.topic,
      header: "Topic",
      id: "topic",
    },
    {
      accessorFn: (row) => row.questionType.join(", "),
      id: "questionType",
      header: "Type",
    },
    {
      accessorFn: (row) => row.season.join(", "),
      header: "Season",
      id: "season",
    },
  ],
  PartTwo: [
    {
      accessorKey: "prompt",
      header: "Prompt",
      size: 180,
    },
    {
      accessorKey: "type",
      header: "Type",
    },
    {
      accessorFn: (row) => row.season.join(", "),
      header: "Season",
      id: "season",
    },
  ],
  PartThree: [
    {
      accessorKey: "question",
      header: "Prompt",
      size: 180,
    },
    {
      accessorKey: "type",
      header: "Type",
    },
    {
      accessorFn: (row) => row.season.join(", "),
      header: "Season",
      id: "season",
    },
  ],
};

export const columnOrder = {
  PartOneTopic: ["topic", "season", "mrt-row-expand"],
  PartOne: ["question", "topic", "questionType", "season", "mrt-row-expand"],
  PartTwo: ["prompt", "type", "season", "mrt-row-expand"],
  PartThree: ["question", "type", "season", "mrt-row-expand"],
};

export const selectColumnOrder = {
  PartOneTopic: ["mrt-row-select", "topic", "season", "mrt-row-expand"],
  PartOne: [
    "mrt-row-select",
    "question",
    "questionType",
    "season",
    "mrt-row-expand",
  ],
  PartTwo: ["mrt-row-select", "prompt", "type", "season", "mrt-row-expand"],
  PartThree: ["mrt-row-select", "question", "type", "season", "mrt-row-expand"],
};

export const speakingTypeNames = {
  p1: "PartOne",
  p2: "PartTwo",
  p3: "PartThree",
  p1t: "PartOneTopic",
  p2n3: "PartTwo",
};

export const speakingPromptTypes = {
  p1: "question",
  p2: "prompt",
  p3: "question",
  p1t: "topic",
  p2n3: "prompt",
};
