import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

const initalState = {
  text: "",
  voice: "female",
  showDetail: false,
  separator: ".",
  textList: [],
  activeIndex: 0,
};

const store = (set) => ({
  ...initalState,
  setActiveIndex: (index) =>
    set((state) => {
      state.activeIndex = index;
    }),
  createPractice: (text, voice, separator) =>
    set((state) => {
      state.text = text;
      state.voice = voice;
      state.separator = separator;
      let textList;
      switch (separator) {
        case ".":
          textList = text
            .match(/[^.!?\n]+[.!?]+|[^.!?\n]+$/g)
            ?.filter((sentence) => sentence.trim() !== "");
          break;
        case "\n":
          textList = text.split(/\n+/g);
          break;
        case "p":
          textList = [text];
          break;
      }
      state.textList = [
        ...textList.map((txt) => ({
          text: txt.trim(),
          voice,
          wordBoundary: null,
          sampleUrl: "",
          result: null,
          recordUrl: "",
        })),
      ];
      state.showDetail = true;
    }),
  handleActiveTextChange: (key, value) =>
    set((state) => {
      state.textList[state.activeIndex][key] = value;
    }),
  reset: () => set(initalState),
});

const useStore = create(immer(store), { name: "shadowing" });

export default useStore;
