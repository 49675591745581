import { useQuery } from "@tanstack/react-query";
import { API, Auth } from "aws-amplify";

const useUserList = () => {
  return useQuery<string[]>({
    queryKey: ["userList"],
    queryFn: async () => {
      const response = await API.get("aws", "/listUsers", {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      });
      return response.usernames;
    },
  });
};

export default useUserList;
