import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface SingleSelectProps {
  options: string[];
  value: string;
  setValue: (value: string) => void;
  label: string;
  disabled?: boolean;
  loading?: boolean;
}

const SingleSelect: React.FC<SingleSelectProps> = ({
  options,
  value,
  setValue,
  label,
  disabled,
  loading,
}) => {
  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    setValue(event.target.value);
  };

  const inputLabelId = `single-select-outlined-${label}`;

  const selectId = `single-select-outlined`;

  return (
    <FormControl variant="outlined" sx={{ minWidth: 120 }}>
      <InputLabel id={inputLabelId}>{label}</InputLabel>
      <Select
        labelId={inputLabelId}
        id={selectId}
        value={value}
        onChange={handleChange}
        label={label}
        disabled={!!disabled}
      >
        {loading ? (
          <MenuItem value="">
            <CircularProgress size={20} />
          </MenuItem>
        ) : (
          options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
};

export default SingleSelect;
