import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import FolderIcon from "@mui/icons-material/Folder";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
} from "@mui/material";
import { PartOne, PartThree } from "../API";
import { createdAtAsc } from "../utilities";

// Define the props for QuestionStack component
interface QuestionStackProps {
  topic: any;
  questionModal: any;
}

const QuestionStack: React.FC<QuestionStackProps> = ({
  topic,
  questionModal: QuestionModal,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<
    PartOne | PartThree | null
  >(null);
  const type = topic.typeName;
  let questions;
  type === "PartOneTopic"
    ? (questions = topic.questions.items)
    : (questions = topic.partThree.items);

  const handleEdit = (question: PartOne | PartThree) => {
    setSelectedQuestion(question);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedQuestion(null);
  };
  return (
    <>
      <List>
        {questions &&
          questions.sort(createdAtAsc).map((question: any) => (
            <ListItem
              key={question.id}
              secondaryAction={
                <>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => handleEdit(question)}
                  >
                    <EditIcon />
                  </IconButton>
                </>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={question.question}
                secondary={question.typeName}
              />
            </ListItem>
          ))}
      </List>
      <IconButton
        color="primary"
        aria-label="add"
        sx={{ display: "block" }}
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </IconButton>
      {open && (
        <>
          {type === "PartOneTopic" ? (
            <QuestionModal
              open={open}
              handleClose={handleClose}
              selectedQuestion={selectedQuestion}
              topicID={topic.id}
            />
          ) : (
            <QuestionModal
              open={open}
              handleClose={handleClose}
              selectedQuestion={selectedQuestion}
              partTwoID={topic.id}
            />
          )}
        </>
      )}
    </>
  );
};

export default QuestionStack;
