import { Box, Button, MobileStepper, Typography } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import ShadowingComponent from "./ShadowingComponent";
import useStore from "./store";
import { useLocation, useNavigate } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const ShadowingDetail: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { textList, activeIndex, setActiveIndex, reset } = useStore(
    (state) => ({
      textList: state.textList,
      activeIndex: state.activeIndex,
      setActiveIndex: state.setActiveIndex,
      reset: state.reset,
    })
  );
  const maxSteps = textList.length;
  const handleNext = () => {
    setActiveIndex(activeIndex + 1);
  };

  const handleBack = () => {
    setActiveIndex(activeIndex - 1);
  };
  const onGoBack = () => {
    if (location.state?.fromFinal) {
      navigate(-1);
    } else {
      reset();
    }
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", padding: 2 }}>
        <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
          Shadowing Practice
        </Typography>
        <Button
          // variant="outlined"
          onClick={onGoBack}
          startIcon={<ExitToAppIcon />}
          sx={{ display: "flex" }}
        >
          Exit
        </Button>
      </Box>

      <Box sx={{ width: "100%", p: 2 }}>
        <ShadowingComponent />
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeIndex}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeIndex === maxSteps - 1}
          >
            Next
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeIndex === 0}
          >
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
    </Box>
  );
};

export default ShadowingDetail;
