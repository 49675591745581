import SingleSelect from "./SingleSelect";
import { useSnackbar } from "notistack";
import useUserList from "../hooks/useUserList";

interface UserDropdownProps {
  user: string;
  setUser: (user: string) => void;
  label?: string;
}

const UserDropdown: React.FC<UserDropdownProps> = ({
  user,
  setUser,
  label,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, isError, isSuccess } = useUserList();
  if (isError) {
    enqueueSnackbar("Network error, please try again later", {
      variant: "error",
    });
  }
  return (
    <SingleSelect
      loading={isLoading}
      options={isSuccess ? data : []}
      value={isLoading ? "" : user}
      setValue={setUser}
      label={label || "User List"}
    />
  );
};

export default UserDropdown;
