import React, { useState, ChangeEvent, FormEvent } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createPartOneTopic,
  updatePartOneTopic,
  deletePartOneTopic,
} from "../utilities/request";
import { seasons } from "../customConfig";
import { validateObjectNotEmpty } from "../utilities/validation";
import MultiSelect from "../components/MultiSelect";
import { PartOneTopic } from "../API";
import { createdAtDesc } from "../utilities";

interface DialogValue {
  topic: string;
  season: string[];
  typeName: "PartOneTopic";
}

const initialDialogValue: DialogValue = {
  topic: "",
  season: ["2024-3"],
  typeName: "PartOneTopic",
};

interface PartOneTopicAutoCompleteProps {
  setTopic: (data: PartOneTopic | null) => void;
  topic: PartOneTopic | null;
  topics: any[];
  isLoading: boolean;
}

const PartOneTopicAutoComplete: React.FC<PartOneTopicAutoCompleteProps> = ({
  setTopic,
  topics,
  topic,
  isLoading,
}) => {
  const queryClient = useQueryClient();

  const [open, toggleOpen] = useState(false);
  const createPartOneTopicMutation = useMutation({
    mutationFn: createPartOneTopic,
    onSuccess: (data: any) => {
      console.log("success", data);
      queryClient
        .invalidateQueries({ queryKey: ["partOneTopics"] })
        .then(() => {
          handleClose();
        });
    },
  });
  const updatePartOneTopicMutation = useMutation({
    mutationFn: updatePartOneTopic,
    onSuccess: (data: any) => {
      console.log("success", data);
      queryClient
        .invalidateQueries({ queryKey: ["partOneTopics"] })
        .then(() => {
          handleClose();
        });
    },
  });

  const deletePartOneTopicMutation = useMutation({
    mutationFn: deletePartOneTopic,
    onSuccess: (data: any) => {
      console.log("success", data);
      queryClient
        .invalidateQueries({ queryKey: ["partOneTopics"] })
        .then(() => {
          handleClose();
          setTopic(null);
        });
    },
  });

  const handleClose = () => {
    setDialogValue(initialDialogValue);
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] =
    useState<DialogValue>(initialDialogValue);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (validateObjectNotEmpty(dialogValue)) {
      if (topic) {
        updatePartOneTopicMutation.mutate(dialogValue);
      } else {
        //updatePartOneTopicMutation
        createPartOneTopicMutation.mutate(dialogValue);
      }
    }
  };

  const handleEdit = () => {
    toggleOpen(true);
    if (topic) {
      setDialogValue({
        id: topic.id,
        topic: topic.topic,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        season: topic.season,
        typeName: "PartOneTopic",
      });
    }
  };
  const onDelete = () => {
    if (topic) {
      deletePartOneTopicMutation.mutate(topic.id);
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={topic}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({ ...dialogValue, topic: newValue });
            });
          } else if (newValue && "id" in newValue) {
            setTopic(newValue);
          } else {
            setTopic(null);
          }
        }}
        filterSelectedOptions
        loading={isLoading}
        loadingText="loading"
        id="part 1 topics"
        options={topics.sort(createdAtDesc) || []}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          return option.topic;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.topic}
          </li>
        )}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Part One Topics"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {topic && <Button onClick={handleEdit}>edit</Button>}
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Create new part one topic</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="part1topic"
              value={dialogValue.topic}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setDialogValue({
                  ...dialogValue,
                  topic: event.target.value,
                })
              }
              label="topic"
              type="text"
              variant="standard"
            />
            <MultiSelect
              value={dialogValue.season}
              options={seasons}
              setValue={(season: string[] | string) =>
                setDialogValue({
                  ...dialogValue,
                  season: Array.isArray(season)
                    ? season
                    : season
                    ? [season]
                    : [],
                })
              }
              label="Season"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">{topic ? "Update" : "Create"}</Button>
            {topic && <Button onClick={onDelete}>Delete</Button>}
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default PartOneTopicAutoComplete;
