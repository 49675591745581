import { useState, useEffect } from "react";

type SetValue<T> = React.Dispatch<React.SetStateAction<T | null>>;

function useLocalStorage<T>(
  key: string,
  initialValue: T | null = null
): [T | null, SetValue<T>] {
  const readValueFromLocalStorage = (): T | null => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  };

  const [storedValue, setStoredValue] = useState<T | null>(
    readValueFromLocalStorage
  );

  const setValue: SetValue<T> = (value) => {
    try {
      const newValue = value instanceof Function ? value(storedValue) : value;
      window.localStorage.setItem(key, JSON.stringify(newValue));
      setStoredValue(newValue);
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };

  useEffect(() => {
    setStoredValue(readValueFromLocalStorage);
  }, [key]);

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValueFromLocalStorage);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key, setStoredValue]);

  return [storedValue, setValue];
}

export default useLocalStorage;
