export const validateNotEmpty = (input: string | any[]): boolean => {
  if (typeof input === "string") {
    return input.trim() !== "";
  } else if (Array.isArray(input)) {
    return input.length > 0;
  } else {
    throw new Error("Invalid input - expected string or array");
  }
};

export const validateObjectNotEmpty = (input: {
  [key: string]: any;
}): boolean => {
  for (let key in input) {
    if (input.hasOwnProperty(key)) {
      let value = input[key];
      if (typeof value === "string" && value.trim() === "") {
        return false;
      } else if (Array.isArray(value) && value.length === 0) {
        return false;
      } else if (
        typeof value === "object" &&
        value !== null &&
        Object.keys(value).length === 0
      ) {
        return false;
      }
    }
  }
  return true;
};

export const validateHomework = (hw: any) => {
  return (
    validateNotEmpty(hw.student) &&
    validateNotEmpty(hw.instruction) &&
    validateNotEmpty(hw.title)
  );
};
