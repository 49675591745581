import {
  ListItem,
  IconButton,
  Collapse,
  Stack,
  Box,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AudioPlayer from "./AudioPlayer";
import { useState } from "react";
import ConfirmationModal from "./ConfirmationModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteRecord, updateSpeakingRecordText } from "../utilities/request";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

interface RecordProp {
  data: any;
}

const Record: React.FC<RecordProp> = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openText, setOpenText] = useState(false);
  const [openTextEdit, setOpenTextEdit] = useState(false);

  const queryClient = useQueryClient();
  const { title, audio, text, id, student, type, typeID, createdAt } = data;
  const [editedText, setEditedText] = useState(text);
  const deleteMutation = useMutation({
    mutationFn: (id: string) => deleteRecord(id),
    onSuccess: (data: any) => {
      console.log("delete success", data);
      queryClient.invalidateQueries({
        queryKey: ["speakingRecord", student, type, typeID],
      });
      setOpenConfirmation(false);
      enqueueSnackbar("Record deleted successfully", { variant: "success" });
    },
    onError: (error: any) => {
      console.log("delete error", error);
      enqueueSnackbar("Error while deleting Record, please try again later", {
        variant: "error",
      });
    },
  });
  const updateTextMutation = useMutation({
    mutationFn: (text: string) => updateSpeakingRecordText(text, id),
    onSuccess: (data: any) => {
      console.log("update text success", data);
      queryClient.invalidateQueries({
        queryKey: ["speakingRecord", student, type, typeID],
      });
      onCancelEdit();
      enqueueSnackbar("Text updated successfully", { variant: "success" });
    },
  });
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const onConfirm = () => {
    deleteMutation.mutate(id);
    onClose();
  };
  const onClose = async () => {
    setOpenConfirmation(false);
  };
  const onCancelEdit = () => {
    setOpenTextEdit(false);
    setEditedText("");
  };
  const handleEditedTextSumbit = () => {
    updateTextMutation.mutate(editedText);
  };

  return (
    <>
      <ListItem
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => setOpenConfirmation(true)}
            color="primary"
          >
            <DeleteIcon />
          </IconButton>
        }
      >
        <AudioPlayer
          s3Key={audio}
          title={title}
          text={text}
          setOpenText={setOpenText}
          openText={openText}
          createdAt={createdAt}
        />
      </ListItem>
      <Collapse in={openText} unmountOnExit>
        {openTextEdit ? (
          <Box
            sx={{
              bgcolor: "#e8f4fd",
              padding: 1,
              borderRadius: "4px",
              my: 1,
              ml: { xs: 0, sm: 1, md: 2 },
              mr: { xs: 0, sm: 3, md: 6 },
            }}
          >
            <CKEditor
              editor={Editor}
              data={text}
              onChange={(_event, editor) => {
                const data = editor.getData();
                setEditedText(data);
              }}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="outlined" onClick={onCancelEdit}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleEditedTextSumbit}
                disabled={updateTextMutation.isPending}
              >
                {updateTextMutation.isPending ? "saving" : "Submit"}
              </Button>
            </Box>
          </Box>
        ) : (
          <Stack
            sx={{
              bgcolor: "#e8f4fd",
              padding: 1,
              borderRadius: "4px",
              my: 1,
              ml: { xs: 0, sm: 1, md: 2 },
              mr: { xs: 0, sm: 3, md: 6 },
            }}
          >
            <Button
              startIcon={<EditIcon />}
              onClick={() => setOpenTextEdit(true)}
              sx={{ alignSelf: "flex-end" }}
            >
              Edit
            </Button>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </Stack>
        )}
      </Collapse>

      <ConfirmationModal
        isOpen={openConfirmation}
        message="Are you sure that you want to delete this record? "
        onConfirm={onConfirm}
        onClose={onClose}
        isLoading={deleteMutation.isPending}
      />
    </>
  );
};

export default Record;
