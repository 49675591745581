import { Stack, Button, Box } from "@mui/material";
import { useState } from "react";
import { SpeakingRecord } from "../API";
import { Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateFinalNote } from "../utilities/request";
import { useSnackbar } from "notistack";
import InfoBox from "../layouts/InfoBox";

interface FinalNoteProps {
  finalRecord: SpeakingRecord;
}

const FinalNote: React.FC<FinalNoteProps> = ({ finalRecord }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [openEditor, setOpenEditor] = useState(false);
  const { note, id } = finalRecord;
  const [text, setText] = useState(note || "");

  const updateNoteMutation = useMutation({
    mutationFn: (text: string) => updateFinalNote(id, text),
    onSuccess: (data: any) => {
      const { student, type, typeID } = data.data.updateSpeakingRecord;
      queryClient.invalidateQueries({
        queryKey: ["speakingRecord", student, type, typeID],
      });
      enqueueSnackbar("Final version was created successfully", {
        variant: "success",
      });
      setOpenEditor(false);
    },
    onError: (error: any) => {
      console.log("Update Final note  error", error);
      enqueueSnackbar(
        "Error while update Final version, please try again later",
        {
          variant: "error",
        }
      );
    },
  });
  const handleSave = () => {
    updateNoteMutation.mutate(text);
  };

  return (
    <Stack sx={{ display: "flex", mb: 2 }}>
      {!note && (
        <Button
          sx={{ alignSelf: "flex-end" }}
          startIcon={<AddIcon />}
          onClick={() => setOpenEditor(!openEditor)}
        >
          {openEditor ? "Close" : "Add Note"}
        </Button>
      )}
      {note && !openEditor && (
        <InfoBox>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body1" color="initial">
              Note:
            </Typography>
            <Button
              startIcon={<EditIcon />}
              onClick={() => setOpenEditor(!openEditor)}
              sx={{ alignSelf: "flex-end" }}
            >
              Edit
            </Button>
          </Box>

          <div dangerouslySetInnerHTML={{ __html: note }}></div>
        </InfoBox>
      )}

      {openEditor && (
        <>
          <Typography variant="body1" color="initial">
            Note
          </Typography>
          <CKEditor
            editor={Editor}
            data={text}
            onChange={(_event, editor) => {
              const data = editor.getData();
              setText(data);
            }}
          />
          <Button onClick={handleSave}>
            {updateNoteMutation.isPending ? "Saving..." : "Save"}
          </Button>
        </>
      )}
    </Stack>
  );
};

export default FinalNote;
