export const listPartOneTopics = /* GraphQL */ `
  query ListPartOneTopics(
    $filter: ModelPartOneTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartOneTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        topic
        typeName
        season
        questions {
          items {
            id
            typeName
            question
            questionType
            season
            createdAt
            updatedAt
            topicID
          }
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listPartTwos = /* GraphQL */ `
  query ListPartTwos(
    $filter: ModelPartTwoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartTwos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        prompt
        subQuestions
        season
        type
        partThree {
          items {
            id
            typeName
            question
            partTwoID
            type
            season
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listPartOnes = /* GraphQL */ `
  query ListPartOnes(
    $filter: ModelPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartOnes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        topic {
          topic
        }
        question
        questionType
        season
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getPartOneTopic = /* GraphQL */ `
  query GetPartOneTopic($id: ID!) {
    getPartOneTopic(id: $id) {
      id
      topic
      typeName
      season
      questions {
        items {
          id
          typeName
          question
          questionType
          season
        }
      }
    }
  }
`;

export const getPartTwo = /* GraphQL */ `
  query GetPartTwo($id: ID!) {
    getPartTwo(id: $id) {
      id
      typeName
      prompt
      subQuestions
      season
      type
      partThree {
        items {
          id
          typeName
          question
          partTwoID
          type
          season
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
