import { useState, FC, useEffect } from "react";
import { Typography, Box, CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import PartOneTopicAutoComplete from "./PartOneTopicAutoComplete";
import { listPartOneTopics } from "../utilities/request";
import QuestionStack from "../components/QuestionStack";
import PartOneModal from "../components/PartOneModal";
import { PartOneTopic } from "../API";

// Define the type for the topic data returned from your query
// Replace `any` with the actual type of the data

const PartOneForm: FC = () => {
  const [topic, setTopic] = useState<PartOneTopic | null>(null);
  const { data, isLoading, isFetching, isSuccess, isError, error } = useQuery({
    queryKey: ["partOneTopics"],
    queryFn: listPartOneTopics,
    select: (data: any) => data.data.listPartOneTopics.items,
  });
  useEffect(() => {
    if (isSuccess) {
      if (topic) {
        const newTopic = data.find((t: any) => t.id === topic.id);
        setTopic(newTopic);
      }
    } else if (isError) {
      console.error("Query failed", error);
    }
    // Rerun this effect when the query's data or error changes
  }, [data, error, isSuccess, isError]);
  return (
    <Box>
      <Typography>partOne form</Typography>

      {isFetching && <CircularProgress color="inherit" size={20} />}
      {isSuccess && (
        <PartOneTopicAutoComplete
          setTopic={setTopic}
          topic={topic}
          topics={data}
          isLoading={isLoading}
        />
      )}
      {topic && <QuestionStack topic={topic} questionModal={PartOneModal} />}
    </Box>
  );
};

export default PartOneForm;
