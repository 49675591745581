import { Box } from "@mui/material";
import { ReactNode } from "react";

interface InfoBoxProps {
  children: ReactNode;
}
const InfoBox: React.FC<InfoBoxProps> = ({ children }) => {
  return (
    <Box
      sx={{
        bgcolor: "info.main",
        padding: { xs: 1, sm: 2 },
        borderRadius: "6px",
        my: 2,
      }}
    >
      {children}
    </Box>
  );
};

export default InfoBox;
