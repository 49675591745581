import { Container, Paper, Box } from "@mui/material";
import { ReactNode } from "react";
import NavBar from "./components/NavBar";

interface RootLayoutProps {
  children: ReactNode;
}

const RootLayout: React.FC<RootLayoutProps> = ({ children }) => {
  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "100vh",
        bgcolor: "rgba(0, 0, 0, 0.05)",
      }}
    >
      <NavBar />
      <Container maxWidth="lg" sx={{ p: { xs: 0 } }}>
        <Paper elevation={3} sx={{ mt: { xs: 1, sm: 2 }, width: "100%" }}>
          {children}
        </Paper>
      </Container>
    </Box>
  );
};

export default RootLayout;
