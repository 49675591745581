import { API, Auth } from "aws-amplify";

import Cookie from "universal-cookie";

export async function getTokenOrRefresh() {
  const cookie = new Cookie();
  const azureToken = cookie.get("azureToken");

  if (azureToken === undefined) {
    try {
      const token: string = await API.get("aws", "/azureToken", {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      });
      cookie.set("azureToken", token, { maxAge: 540, path: "/" });

      //   console.log("Token fetched from back-end: " + token);
      return token;
    } catch (err) {
      console.log(err);
      return { azureToken: null };
    }
  } else {
    // console.log("Token fetched from cookie: " + azureToken);
    return azureToken;
  }
}
