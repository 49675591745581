import { useLocation } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  CardActions,
  Button,
} from "@mui/material";
import RenderPartOne from "../components/Render/RenderPartOne";
import RenderPartTwoList from "../components/Render/RenderPartTwoList";
import RenderPartThree from "../components/Render/RenderPartThree";
import RenderPartOneTopicList from "../components/Render/RenderPartOneTopicList";
import RenderPartTwoAndThreeList from "../components/Render/RenderPartTwoAndThreeList";
import dayjs from "dayjs";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";
import { Link } from "react-router-dom";

const HomeworkDetail: React.FC = () => {
  const { state: data } = useLocation();
  const { title, instruction, dueDate, student, p1, p2, p3, p1t, p2n3 } =
    data.homework;
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent sx={{ p: { xs: 0.25, sm: 1, md: 2 } }}>
        <DateTimeField
          defaultValue={dayjs(dueDate)}
          label="Due date"
          readOnly
        />
        <Box
          sx={{
            bgcolor: "#e8f4fd",
            padding: 1,
            borderRadius: "4px",
            my: 2,
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: instruction }} />
        </Box>
        <Box
          sx={{
            my: { xs: 1, sm: 2, md: 3, lg: 4 },
            p: { xs: 0, sm: 1, md: 2 },
          }}
        >
          {p1t && p1t.length > 0 && (
            <RenderPartOneTopicList p1t={p1t} student={student} />
          )}
          {p2n3 && p2n3.length > 0 && (
            <RenderPartTwoAndThreeList p2n3={p2n3} student={student} />
          )}
          {p1 && p1.length > 0 && <RenderPartOne p1={p1} student={student} />}
          {p2 && p2.length > 0 && (
            <RenderPartTwoList p2={p2} student={student} />
          )}
          {p3 && p3.length > 0 && <RenderPartThree p3={p3} student={student} />}
        </Box>
      </CardContent>
      <CardActions>
        <Button variant="outlined" component={Link} to="/" sx={{ ml: "auto" }}>
          Go Back
        </Button>
      </CardActions>
    </Card>
  );
};

export default HomeworkDetail;
