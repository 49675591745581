import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  AccordionDetails,
  AccordionActions,
  Box,
  Chip,
} from "@mui/material";
import AudioPlayer from "../AudioPlayer";
import FinalForm from "../../form/FinalForm";
import FinalNote from "../FinalNote";
import useStore from "../shadowing/store";
import { removeHTMLTagsAndSymbols } from "../../utilities";
import GroupIcon from "@mui/icons-material/Group";
import InfoBox from "../../layouts/InfoBox";

interface RenderFinalVersionProps {
  finalRecord: any;
  student: string;
  typeName: string;
  id: string;
  backToPractice: () => void;
}

const RenderFinalVersion: React.FC<RenderFinalVersionProps> = ({
  finalRecord,
  student,
  typeName,
  id,
  backToPractice,
}) => {
  const reset = useStore((state) => state.reset);
  const [openForm, setOpenForm] = useState<boolean>(false);
  if (finalRecord) {
    return (
      <>
        <AccordionDetails sx={{ p: { xs: 0, sm: 1, md: 2 } }}>
          <FinalNote finalRecord={finalRecord} />
          <Chip label="Final Version" color="secondary" sx={{ mb: 2 }} />
          {openForm ? (
            <FinalForm
              student={student}
              type={typeName}
              typeID={id}
              setOpen={setOpenForm}
              finalData={finalRecord}
            />
          ) : (
            <Box>
              <InfoBox>
                <div
                  dangerouslySetInnerHTML={{ __html: finalRecord.text }}
                ></div>
              </InfoBox>
              <AudioPlayer s3Key={finalRecord.audio} />
            </Box>
          )}
        </AccordionDetails>
        {!openForm && (
          <AccordionActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button variant="outlined" onClick={backToPractice}>
              Back
            </Button>
            <Button
              startIcon={<GroupIcon />}
              variant="outlined"
              component={RouterLink}
              to="/shadowing"
              state={{
                text: removeHTMLTagsAndSymbols(finalRecord.text),
                fromFinal: true,
              }}
              onClick={reset}
            >
              Shadowing
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpenForm(true)}
              disabled={openForm}
            >
              {finalRecord ? "Edit" : "Create"}
            </Button>
          </AccordionActions>
        )}
      </>
    );
  }
  return (
    <>
      <AccordionDetails>
        <FinalForm
          student={student}
          type={typeName}
          typeID={id}
          setOpen={setOpenForm}
          finalData={finalRecord}
          backToPractice={backToPractice}
        />
      </AccordionDetails>
    </>
  );
};

export default RenderFinalVersion;
