import { Chip, Divider } from "@mui/material";
import { useQueries } from "@tanstack/react-query";
import { getSpeakingQuestionsByTypeNameAndId } from "../../utilities/request";
import RenderPartTwoAndThree from "./RenderPartTwoAndThree";

interface RenderPartTwoAndThreeListProps {
  p2n3: string[];
  student: string;
}

const RenderPartTwoAndThreeList: React.FC<RenderPartTwoAndThreeListProps> = ({
  p2n3,
  student,
}) => {
  const PartTwoAndThreeQueries = useQueries({
    queries: p2n3.map((id: string) => {
      return {
        queryKey: ["PartTwo", id],
        queryFn: () => getSpeakingQuestionsByTypeNameAndId("PartTwo", id),
        select: (data: any) => data.data.getPartTwo,
      };
    }),
  });
  const isSuccess = PartTwoAndThreeQueries.every((q) => q.isSuccess);
  const part2n3 = PartTwoAndThreeQueries.map((q) => q.data);
  if (!isSuccess) return <div>isLoading ...</div>;
  return (
    <>
      <Chip color="primary" label="Part 2 & 3" sx={{ mt: 2 }} />
      <Divider sx={{ my: 2 }} />
      {isSuccess &&
        part2n3.map((p2: any, index: number) => (
          <RenderPartTwoAndThree
            p2={p2}
            student={student}
            index={index}
            key={p2.id}
          />
        ))}
    </>
  );
};

export default RenderPartTwoAndThreeList;
