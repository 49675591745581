import React, { useState, useEffect, useRef, RefObject } from "react";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import PauseCircleOutlinedIcon from "@mui/icons-material/PauseCircleOutlined";
// import PauseIcon from "@mui/icons-material/Pause";
import { IconButton } from "@mui/material";

interface AudioPlayerProps {
  audioURL: string;
  audioRef?: RefObject<HTMLAudioElement>;
  onTimeUpdate?: (event: any) => void;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  audioURL,
  audioRef,
  onTimeUpdate,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const internalAudioRef = useRef<HTMLAudioElement | null>(null);
  const currentAudioRef = audioRef || internalAudioRef;

  useEffect(() => {
    const audio = currentAudioRef.current;

    if (audio) {
      const handleAudioPause = () => setIsPlaying(false);
      const handleAudioPlay = () => setIsPlaying(true);

      audio.addEventListener("pause", handleAudioPause);
      audio.addEventListener("play", handleAudioPlay);

      return () => {
        audio.removeEventListener("pause", handleAudioPause);
        audio.removeEventListener("play", handleAudioPlay);
      };
    }
  }, []);

  const togglePlayPause = () => {
    const audio = currentAudioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
    }
  };

  return (
    <>
      <IconButton onClick={togglePlayPause} color="primary" size="small">
        {isPlaying ? (
          <PauseCircleOutlinedIcon fontSize="large" />
        ) : (
          <PlayCircleOutlinedIcon fontSize="large" />
        )}
      </IconButton>
      <audio ref={currentAudioRef} src={audioURL} onTimeUpdate={onTimeUpdate} />
    </>
  );
};

export default AudioPlayer;
