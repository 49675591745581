import { useAuthenticator } from "@aws-amplify/ui-react";
import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import { isAdmin } from "../utilities";
import BasicTable from "../components/BasicTable";
import { useState } from "react";
import UserDropdown from "../components/UserDropdown";

const QuestionBankPage: React.FC = () => {
  const [selectedUser, setSelectedUser] = useState<string>("");
  const { user } = useAuthenticator();
  return (
    <>
      {isAdmin(user) && (
        <>
          <Button
            component={NavLink}
            to="/questionBank/create"
            variant="contained"
          >
            Add Question
          </Button>
          <UserDropdown user={selectedUser} setUser={setSelectedUser} />
        </>
      )}
      <BasicTable selectedUser={selectedUser} />
    </>
  );
};

export default QuestionBankPage;
