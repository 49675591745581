import { Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { isAdmin } from "../utilities";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user } = useAuthenticator();
  if (!isAdmin(user)) {
    return <Navigate to="/" replace />;
  }

  return children;
};
export default ProtectedRoute;
