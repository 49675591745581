import { Stack, TextField } from "@mui/material";

interface SubQuestionsProps {
  questions: string[];
  setQuestions: (questions: string[]) => void;
}

const SubQuestions: React.FC<SubQuestionsProps> = ({
  questions,
  setQuestions,
}) => {
  return (
    <Stack>
      <TextField
        label="Sub Question 1"
        value={questions[0]}
        onChange={(e) => {
          questions[0] = e.target.value;
          setQuestions([...questions]);
        }}
      />
      <TextField
        label="Sub Question 2"
        value={questions[1]}
        onChange={(e) => {
          questions[1] = e.target.value;
          setQuestions([...questions]);
        }}
      />
      <TextField
        label="Sub Question 3"
        value={questions[2]}
        onChange={(e) => {
          questions[2] = e.target.value;
          setQuestions([...questions]);
        }}
      />
      <TextField
        label="Sub Question 4"
        value={questions[3]}
        onChange={(e) => {
          questions[3] = e.target.value;
          setQuestions([...questions]);
        }}
      />
    </Stack>
  );
};

export default SubQuestions;
