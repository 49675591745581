import { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";

const useAzureToken = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<any>(null);

  const fetchToken = async () => {
    try {
      setLoading(true);
      const response: string = await API.get("aws", "/azureToken", {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      });
      setToken(response);
      setLoading(false);
    } catch (err) {
      setError(err);
      console.log("err fetchToken", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchToken();
    const tokenIntervalId = setInterval(fetchToken, 9 * 60 * 1000); // Refresh the token every 9 minutes

    return () => {
      clearInterval(tokenIntervalId); // Clear the interval on component unmount
    };
  }, []);

  return { loading, token, error };
};

export default useAzureToken;
