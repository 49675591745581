import React, { useState, ChangeEvent, FormEvent } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createPartTwo,
  updatePartTwo,
  deletePartTwo,
} from "../utilities/request";
import { seasons, partTwoType } from "../customConfig";
import { validateObjectNotEmpty } from "../utilities/validation";
import MultiSelect from "../components/MultiSelect";
import { PartTwo } from "../API";
import SingleSelect from "../components/SingleSelect";
import SubQuestions from "../components/SubQuestions";
import { createdAtDesc } from "../utilities";

interface DialogValue {
  prompt: string;
  subQuestions: string[];
  type: string;
  season: string[];
  typeName: "PartTwo";
}

const initialDialogValue: DialogValue = {
  prompt: "",
  subQuestions: ["", "", "", ""],
  type: "",
  season: ["2024-3"],
  typeName: "PartTwo",
};

interface PartTwoAutoCompleteProps {
  setPartTwo: (data: PartTwo | null) => void;
  partTwo: PartTwo | null;
  partTwos: PartTwo[];
  isLoading: boolean;
}

const PartTwoAutoComplete: React.FC<PartTwoAutoCompleteProps> = ({
  setPartTwo,
  partTwo,
  partTwos,
  isLoading,
}) => {
  const queryClient = useQueryClient();
  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] =
    useState<DialogValue>(initialDialogValue);
  const handleSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ["partTwos"] }).then(() => {
      handleClose();
    });
  };
  const createPartTwoMutation = useMutation({
    mutationFn: createPartTwo,
    onSuccess: (data: any) => {
      console.log("success", data);
      handleSuccess();
    },
  });
  const updatePartTwoMutation = useMutation({
    mutationFn: updatePartTwo,
    onSuccess: (data: any) => {
      console.log("success", data);
      handleSuccess();
    },
  });

  const deletePartTwoMutation = useMutation({
    mutationFn: deletePartTwo,
    onSuccess: (data: any) => {
      console.log("success", data);
      handleSuccess();
    },
  });
  const handleClose = () => {
    setDialogValue(initialDialogValue);
    toggleOpen(false);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (validateObjectNotEmpty(dialogValue)) {
      if (partTwo) {
        updatePartTwoMutation.mutate(dialogValue);
      } else {
        //updatePartOneTopicMutation
        createPartTwoMutation.mutate(dialogValue);
      }
    }
  };
  const handleEdit = () => {
    toggleOpen(true);
    if (partTwo) {
      setDialogValue({
        id: partTwo.id,
        prompt: partTwo.prompt,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        subQuestions: partTwo.subQuestions,
        type: partTwo.type,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        season: partTwo.season,
        typeName: "PartTwo",
      });
    }
  };

  const onDelete = () => {
    if (partTwo) {
      deletePartTwoMutation.mutate(partTwo.id);
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={partTwo}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({ ...dialogValue, prompt: newValue });
            });
          } else if (newValue && "id" in newValue) {
            setPartTwo(newValue);
          } else {
            setPartTwo(null);
          }
        }}
        filterSelectedOptions
        loading={isLoading}
        loadingText="loading"
        id="part 2"
        options={partTwos.sort(createdAtDesc) || []}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          return option.prompt;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.prompt}
          </li>
        )}
        sx={{ width: 600 }}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="PartTwos"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {partTwo && <Button onClick={handleEdit}>edit</Button>}
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Create new part two</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="part1topic"
              value={dialogValue.prompt}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setDialogValue({
                  ...dialogValue,
                  prompt: event.target.value,
                })
              }
              label="prompt"
              type="text"
              variant="standard"
            />
            <MultiSelect
              value={dialogValue.season}
              options={seasons}
              setValue={(season: string[] | string) =>
                setDialogValue({
                  ...dialogValue,
                  season: Array.isArray(season)
                    ? season
                    : season
                    ? [season]
                    : [],
                })
              }
              label="Season"
            />
            <SingleSelect
              value={dialogValue.type}
              options={partTwoType}
              setValue={(type: string) => {
                setDialogValue({
                  ...dialogValue,
                  type: type,
                });
              }}
              label="Type"
            />
            <SubQuestions
              questions={dialogValue.subQuestions}
              setQuestions={(subQuestions: string[]) => {
                setDialogValue({
                  ...dialogValue,
                  subQuestions: subQuestions,
                });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">{partTwo ? "Update" : "Create"}</Button>
            {partTwo && <Button onClick={onDelete}>Delete</Button>}
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default PartTwoAutoComplete;
