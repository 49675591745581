export function isAdmin(user) {
  const userGroups =
    user.signInUserSession.accessToken.payload["cognito:groups"];
  if (userGroups && userGroups.includes("admin")) {
    return true;
  }
  return false;
}

export const getCurrentFormattedTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hour = String(now.getHours()).padStart(2, "0");
  const minute = String(now.getMinutes()).padStart(2, "0");
  const second = String(now.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day}-${hour}-${minute}-${second}`;
};

export function audioBlobToUint8Array(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const uint8Array = new Uint8Array(arrayBuffer);
      resolve(uint8Array);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(blob);
  });
}

export const createdAtAsc = (a, b) =>
  new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();

export const createdAtDesc = (a, b) =>
  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const removeHTMLTagsAndSymbols = (input) => {
  // create a new instance of DOMParser
  var doc = new DOMParser().parseFromString(input, "text/html");
  // Use the in-built textContent property to get the text content of the parsed HTML string
  var text = doc.body.textContent || "";
  // Now, replace all occurrences of &nbsp; (and potentially other symbols) with a space
  text = text.replace(/&nbsp;/g, " ");
  return text;
};
