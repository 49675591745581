import Recorder from "./Recorder";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { listRecords } from "../utilities/request";
import RenderPractice from "./Render/RenderPractice";
import RenderFinalVersion from "./Render/RenderFinalVersion";
import Loader from "./Loader";
import { createdAtAsc } from "../utilities";

interface RecordingStackProps {
  id: string;
  typeName: string;
  student: string;
  subQuestions?: string[];
}

const RecordingStack: React.FC<RecordingStackProps> = ({
  typeName,
  student,
  id,
  subQuestions,
}) => {
  const [panel, setPanel] = useState<"p" | "r" | "f">("p");
  const { isSuccess, data } = useQuery({
    queryKey: ["speakingRecord", student, typeName, id],
    queryFn: () => listRecords(student, typeName, id),
    select: (data: any) => data.data.recordByStudentTypeTypeID.items,
    enabled: !!student && !!typeName && !!id,
  });

  if (!isSuccess) {
    return <Loader />;
  }
  const records = data
    .filter((record: any) => record.final === false)
    .sort(createdAtAsc);
  const finalRecord = data.find((record: any) => record.final === true);
  const showRecorder = records.length < 5;
  return (
    <>
      {panel === "p" && (
        <RenderPractice
          subQuestions={subQuestions}
          records={records}
          setPanel={setPanel}
          showRecorder={showRecorder}
          isFinal={!!finalRecord}
        />
      )}
      {panel === "r" && (
        <Recorder
          student={student}
          type={typeName}
          id={id}
          backToPractice={() => setPanel("p")}
        />
      )}
      {panel === "f" && (
        <RenderFinalVersion
          finalRecord={finalRecord}
          student={student}
          typeName={typeName}
          id={id}
          backToPractice={() => setPanel("p")}
        />
      )}
    </>
  );
};

export default RecordingStack;
