import {
  Box,
  List,
  AccordionDetails,
  AccordionActions,
  Button,
} from "@mui/material";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import Record from "../Record";

interface RenderPracticeProps {
  records: any;
  subQuestions?: string[];
  setPanel: (value: "p" | "r" | "f") => void;
  showRecorder: boolean;
  isFinal: boolean;
}

const RenderPractice: React.FC<RenderPracticeProps> = ({
  records,
  subQuestions,
  setPanel,
  showRecorder,
  isFinal,
}) => {
  return (
    <>
      <AccordionDetails sx={{ p: { xs: 0, sm: 1, md: 2 } }}>
        {subQuestions && (
          <Box
            sx={{
              border: "1px solid black",
              padding: "10px",
              margin: "10px",
            }}
          >
            <ul>
              {subQuestions.map((subQ: string) => (
                <li key={subQ}>{subQ}</li>
              ))}
            </ul>
          </Box>
        )}
        <List>
          {records &&
            records.length > 0 &&
            records.map((record: any) => {
              return <Record key={record.id} data={record} />;
            })}
        </List>
      </AccordionDetails>
      <AccordionActions>
        <Button
          variant="outlined"
          onClick={() => setPanel("f")}
          startIcon={
            isFinal ? (
              <VerifiedUserIcon color="primary" />
            ) : (
              <PrivacyTipIcon color="disabled" />
            )
          }
        >
          Final Version
        </Button>
        {showRecorder ? (
          <Button
            variant="outlined"
            startIcon={<KeyboardVoiceIcon />}
            onClick={() => setPanel("r")}
          >
            Add a New Record
          </Button>
        ) : (
          <Button
            variant="outlined"
            startIcon={<KeyboardVoiceIcon color="disabled" />}
            disabled
          >
            Reached Limit
          </Button>
        )}
      </AccordionActions>
    </>
  );
};

export default RenderPractice;
