import { Typography, Accordion, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RecordingStack from "../RecordingStack";

interface RenderPartOneTopicProps {
  topic: any;
  index?: number;
  student: string;
}

const RenderPartOneTopic: React.FC<RenderPartOneTopicProps> = ({
  topic,
  index,
  student,
}) => {
  return (
    <>
      <Typography sx={{ my: 2 }}>
        {index !== undefined && `${index + 1}. `}
        {topic.topic}
      </Typography>
      {topic.questions.items.map((q: any, i: number) => (
        <Accordion key={q.id} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ userSelect: "text" }}>{`${i + 1}. ${
              q.question
            }`}</Typography>
          </AccordionSummary>
          <RecordingStack typeName={q.typeName} id={q.id} student={student} />
        </Accordion>
      ))}
    </>
  );
};
export default RenderPartOneTopic;
